import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingAddressesComponent } from './components/shipping-addresses/shipping-addresses.component';
import { AddressPipe } from './pipes/address.pipe';
import { FormDropdownModule } from '@library';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, FormDropdownModule, TranslateModule],
    declarations: [ShippingAddressesComponent, AddressPipe],
    exports: [ShippingAddressesComponent, AddressPipe],
})
export class ShippingAddressesModule {}
