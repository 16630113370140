import { Pipe, PipeTransform } from '@angular/core';
import { ShippingAddress } from '@app/store/shipping-addresses/models';

@Pipe({
    name: 'address',
})
export class AddressPipe implements PipeTransform {
    transform(item: ShippingAddress): string {
        const name = item.restaurantName.trim();
        const address = `${item.address} ${item.address2}`.trim();
        const city = item.city.trim();
        return `${name !== '' ? name + ' - ' : ''}${address}, ${city}`;
    }
}
